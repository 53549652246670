import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Profile = () => {
  return (
    <Container sx={{ backgroundColor: '#444', padding: 3, borderRadius: 2, mt: 3 }}>
      <Box display="flex" alignItems="center" mb={2}>
        <AccountCircleIcon sx={{ marginRight: 1, fontSize: 30, color: '#00bcd4' }} />
        <Typography variant="h5" color="#00bcd4">Обо мне</Typography>
      </Box>
      <Typography variant="body1" color="#ddd">
        С детства увлекаюсь программированием. Активно разрабатываю свои проекты, которые мне интересны, и участвую в разработке графики для ICPC Live.      </Typography>
    </Container>
  );
};

export default Profile;