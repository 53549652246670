import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';

const Header = () => {
  return (
    <AppBar position="static" sx={{ backgroundColor: '#444', padding: { xs: 1, sm: 1.5 } }}>
      <Toolbar sx={{ flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'ceneter', sm: 'center' }, height: 'auto' }}>
      <Box sx={{ flexGrow: 1, textAlign: { xs: 'center', sm: 'left' } }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '2rem' } }}>Корнилович Михаил</Typography>
          <Typography variant="h6" sx={{ fontSize: { xs: '1rem', sm: '1.2rem' }, fontWeight: 'bold' }}>
            Backend Developer
          </Typography>
        </Box>
        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="flex-end" sx={{ width: { xs: '100%', sm: 'auto' }, marginTop: { xs: 1, sm: 0 } }}>
          <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} flexWrap="wrap" justifyContent="flex-end">
            <Box display="flex" alignItems="center" sx={{ marginRight: { sm: 2 }, marginBottom: { xs: 1, sm: 0 } }}>
              <LocationOnIcon sx={{ marginRight: 0.5 }} />
              <Typography variant="body2">Санкт-Петербург, Россия</Typography>
            </Box>
            <Box display="flex" alignItems="center" sx={{ marginRight: { sm: 2 }, marginBottom: { xs: 1, sm: 0 } }}>
              <EmailIcon sx={{ marginRight: 0.5 }} />
              <Typography variant="body2">kornilovichmihail@gmail.com</Typography>
            </Box>
            <Box display="flex" alignItems="center" sx={{ marginRight: { sm: 2 }, marginBottom: { xs: 1, sm: 0 } }}>
              <PhoneIcon sx={{ marginRight: 0.5 }} />
              <Typography variant="body2">+7 (921) 847-28-88</Typography>
            </Box>
            <Box display="flex" alignItems="center" sx={{ marginBottom: { xs: 1, sm: 0 } }}>
              <LanguageIcon sx={{ marginRight: 0.5 }} />
              <Typography variant="body2">mkornilovich.ru</Typography>
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;