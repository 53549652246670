import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

const Skills = () => {
  return (
    <Container sx={{ backgroundColor: '#444', padding: 3, borderRadius: 2, mt: 3 }}>
      <Box display="flex" alignItems="center" mb={2}>
        <StarIcon sx={{ marginRight: 1, fontSize: 30, color: '#00bcd4' }} />
        <Typography variant="h5" color="#00bcd4">Навыки</Typography>
      </Box>
      <Box>
        <ul>
          <li><Typography variant="body1" color="#ddd">C++</Typography></li>
          <li><Typography variant="body1" color="#ddd">Java</Typography></li>
          <li><Typography variant="body1" color="#ddd">Kotlin</Typography></li>
          <li><Typography variant="body1" color="#ddd">Javascript/Typescript + React</Typography></li>
          <li><Typography variant="body1" color="#ddd">Git</Typography></li>
          <li><Typography variant="body1" color="#ddd">Docker</Typography></li>
          <li><Typography variant="body1" color="#ddd">CI/CD</Typography></li>
          <li><Typography variant="body1" color="#ddd">PostgreSQL</Typography></li>
          <li><Typography variant="body1" color="#ddd">Linux</Typography></li>
          <li><Typography variant="body1" color="#ddd">Алгоритмы и структуры данных</Typography></li>
          <li><Typography variant="body1" color="#ddd">TCP/IP</Typography></li>
        </ul>
      </Box>
    </Container>
  );
};

export default Skills;