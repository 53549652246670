import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';

const Education = () => {
  return (
    <Container sx={{ backgroundColor: '#444', padding: 3, borderRadius: 2, mt: 3 }}>
      <Box display="flex" alignItems="center" mb={2}>
        <SchoolIcon sx={{ marginRight: 1, fontSize: 30, color: '#00bcd4' }} />
        <Typography variant="h5" color="#00bcd4">Образование</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h6" color="#ddd">Гимназия имени Александра Невского</Typography>
        <Typography variant="body2" color="#888">Среднее общее образование</Typography>
        <Typography variant="body2" color="#888">2022</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h6" color="#ddd">Университет ИТМО</Typography>
        <Typography variant="body2" color="#888">Прикладная математика и информатика</Typography>
        <Typography variant="body2" color="#888">2026</Typography>
      </Box>
    </Container>
  );
};

export default Education;