import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';

const Experience = () => {
  return (
    <Container sx={{ backgroundColor: '#444', padding: 3, borderRadius: 2, mt: 3 }}>
      <Box display="flex" alignItems="center" mb={2}>
        <WorkIcon sx={{ marginRight: 1, fontSize: 30, color: '#00bcd4' }} />
        <Typography variant="h5" color="#00bcd4">Опыт</Typography>
      </Box>
      <Box mb={2}>
      <Typography variant="body1" color="#ddd">
      Участие в проведении трансляций по ICPC, олимпиад для школьников и студентов (ВКОШП, ВСОШ, Игры Будущего и т.д.). Моя роль заключается в том, чтобы графика для трансляции работала исправно, в исправлении багов по ходу трансляции. Во время последних трансляций занимался обучением новичков
      Примеры трансляций:
    <ul>
      <li><Typography variant="body1" color="#ddd"><Link href="https://vk.cc/cw99yz">NEF 2022</Link></Typography></li>
      <li><Typography variant="body1" color="#ddd"><Link href="https://vk.cc/cw99DM">NEF 2023</Link></Typography></li> 
      <li><Typography variant="body1" color="#ddd"><Link href="https://vk.cc/cw99He">ВСОШ 2024</Link></Typography></li> 
      <li><Typography variant="body1" color="#ddd"><Link href="https://vk.cc/cw99L1">Игры Будущего</Link></Typography></li> 
      </ul>
      </Typography>
      </Box>
    </Container>
  );
};

export default Experience;