import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';

const Projects = () => {
  return (
    <Container sx={{ backgroundColor: '#444', padding: 3, borderRadius: 2, mt: 3 }}>
      <Box display="flex" alignItems="center" mb={2}>
        <FolderIcon sx={{ marginRight: 1, fontSize: 30, color: '#00bcd4' }} />
        <Typography variant="h5" color="#00bcd4">Проекты</Typography>
      </Box>
      <Typography variant="body1" color="#ddd">
      Написание собственного движка для игр на C++ - <Link href="https://github.com/Mond1c/OpenGLEngine">OpenGLEngine</Link>. Участие в разработке любительских игр. 
      </Typography>
      <Typography variant="body1" color="#ddd">
      Принимаю активное участие в разработке графики для трансляций - <Link href="https://github.com/icpc/live-v3">ICPC LIVE Overlay</Link>:
      <ul>
      <li><Typography variant="body1" color="#ddd">добавляю новые функции для повышения интереса просмотра трансляций</Typography></li>
      <li><Typography variant="body1" color="#ddd">исправляю различные баги в коде, в том числе утечки</Typography></li> 
      </ul>
      </Typography>
      <Typography variant="body1" color="#ddd">
      Участвую в проекте <Link href="https://github.com/irdkwmnsb/webrtc-grabber">WebRTC Grabber</Link>: переписываю в нем некоторый api в ветке frontend-rework. Проект предназначен для демонстрации веб-камер и экранов участников во время трансляций
      </Typography>
      <Typography variant="body1" color="#ddd">
      Личные проекты: <Link href="https://github.com/Mond1c/LinAlg">LinAlg</Link>, для которого есть telegram bot, это парсер выражений, который умеет работать с объектами линейной алгебры, а также считать производные, сайт для сдачи домашней работе по ЕГЭ по информатике, написанный на Kotlin + JavaScript с React (сейчас активно переписывается на Go c использованием postgresql). Также есть <Link href="https://github.com/Mond1c/testing_system">тестирующая система</Link>, написанная на Go + JavaScript, которая находиться на стадии разработки.  
      </Typography>
    </Container>
  );
};

export default Projects;